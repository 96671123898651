import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useContext, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "./Components/Loader";
import Layout from "./Components/Layout";
import { AuthContext } from "./Screens/log-in/AuthContext";
import AccessDenied from "./Components/accessDenied";
import GetUserPermissions from "./Components/getPermissions";
const Home = React.lazy(() => import("./Screens/Dashboard/home"));
const PunchInPunchOut = React.lazy(() =>
  import("./Screens/punch-in-punch-out/punch-in-punch-out")
);
const LeaveRequest = React.lazy(() =>
  import("./Screens/leave-request/leave-request")
);
const AttendanceTracking = React.lazy(() =>
  import("./Screens/attendance-tracking/attendance-tracking")
);
const PeopleDirectory = React.lazy(() =>
  import("./Screens/peopleDirectory/peopleDirectory")
);
const LeaveReportTracking = React.lazy(() =>
  import("./Screens/attendance-tracking/leaveReportTracking")
);
const ShiftReportTracking = React.lazy(() =>
  import("./Screens/attendance-tracking/shiftReportTracking")
);
const OvertimeReportTracking = React.lazy(() =>
  import("./Screens/attendance-tracking/overtimeReportTracking")
);
const ApprovalReportHistory = React.lazy(() =>
  import("./Screens/attendance-tracking/approval-report-history")
);
const LogIn = React.lazy(() => import("./Screens/log-in/log-in"));
const Announcement = React.lazy(() =>
  import("./Screens/announcement/announcement")
);
const Dailyreport = React.lazy(() =>
  import("./Screens/DateWiseFullReport/dailyreport")
);
const ResetPassword = React.lazy(() =>
  import("./Screens/log-in/resetPassword")
);
const OtpEnter = React.lazy(() => import("./Screens/log-in/otpEnter"));


const ForgetPassword = React.lazy(() =>
  import("./Screens/log-in/forgetPassword")
);

const PrivacyPolicy = React.lazy(() =>
  import("./Screens/log-in/privacy-policy")
);
const ShiftRequest = React.lazy(() =>
  import("./Screens/shiftManagement/shift-request")
);
const OverTimeRequest = React.lazy(() =>
  import("./Screens/overTimeManagement/overtimeRequest")
);
const PermissionPanel = React.lazy(() =>
  import("./Screens/adminPanel/Permission/permissionPanel")
);
const RolePanel = React.lazy(() =>
  import("./Screens/adminPanel/Role/rolePanel")
);
const RoleEditPanel = React.lazy(() =>
  import("./Screens/adminPanel/Role/roleeditPanel")
);
const EmpOnBoardingPanel = React.lazy(() =>
  import("./Screens/adminPanel/EmpOnBoarding/empOnBoardingPanel")
);
const EmpEditPanel = React.lazy(() =>
  import("./Screens/adminPanel/EmpOnBoarding/empEditPanel")
);
const LeaveListPanel = React.lazy(() =>
  import("./Screens/adminPanel/leaveManagement/leaveListPanel")
);
const ShiftPanel = React.lazy(() =>
  import("./Screens/adminPanel/shiftManagement/shiftPanel")
);
const OverTimePanel = React.lazy(() =>
  import("./Screens/adminPanel/overtimeManagement/overTimePanel")
);
const GetNotificationPanel = React.lazy(() =>
  import("./Screens/adminPanel/pushNotifications/getNotificationPanel")
);

const PolicyPanel = React.lazy(() =>
  import("./Screens/adminPanel/companyPolicy/policyPanel")
);
const HolidayPanel = React.lazy(() =>
  import("./Screens/adminPanel/Holidays/holidayPanel")
);
const EditPolicy = React.lazy(() =>
  import("./Screens/adminPanel/companyPolicy/editPolicy")
);
const EditHolidays = React.lazy(() =>
  import("./Screens/adminPanel/Holidays/editHolidays")
);
const EditDocumentPanel = React.lazy(() =>
  import("./Screens/adminPanel/Edit-Documents/editDocumentPanel")
);
const DocumentEditPanel = React.lazy(() =>
  import("./Screens/adminPanel/Edit-Documents/documentEditPanel")
);
const SalaryPanel = React.lazy(() =>
  import("./Screens/adminPanel/salaryManagement/salaryPanel")
);
const EditSalaryPanel = React.lazy(() =>
  import("./Screens/adminPanel/salaryManagement/editSalaryPanel")
);
const SalarySlipPanel = React.lazy(() =>
  import("./Screens/adminPanel/salarySlip/salarySlipPanel")
);
const Salaryslippdf = React.lazy(() =>
  import("./Screens/adminPanel/salarySlip/salaryslippdf")
);
const ExitForm = React.lazy(() => import("./Screens/exit/exit-form"));

const EExitReport = React.lazy(() =>
  import("./Screens/adminPanel/e-exit/eExitReport")
);
const UserProfile = React.lazy(() =>
  import("./Screens/userProfile/userProfile")
);
const OfflineIndicator = React.lazy(() => import("./OfflineIndicator"));
const SheetPanel = React.lazy(() =>
  import("./Screens/attendance-tracking/sheetPanel")
);
const EditReportPanel = React.lazy(() =>
  import("./Screens/attendance-tracking/EditReportPanel")
);

const PayslipPanel = React.lazy(() =>
  import("./Screens/EmpPaySlip/PayslipPanel")
);

const ExitFormHr = React.lazy(() =>
  import("./Screens/adminPanel/e-exit/e-exit-hr")
);

const AddOrgPanel = React.lazy(() =>
  import("./Screens/adminPanel/AddOrganization/AddOrgPanel")
);

const EditOrgPanel = React.lazy(() =>
  import("./Screens/adminPanel/AddOrganization/EditOrgPanel")
);

const SubscriptionPanel = React.lazy(() =>
  import("./Screens/adminPanel/Subscription/subscriptionPanel")
);

const EditSubscriptionPanel = React.lazy(() =>
  import("./Screens/adminPanel/Subscription/editSubscriptionPanel")
);

const Department = React.lazy(() =>
  import("./Screens/adminPanel/Department/DepartmentPanel")
);

const EditDepartment = React.lazy(() =>
  import("./Screens/adminPanel/Department/EditDepartmentPanel")
);

const Designation = React.lazy(() =>
  import("./Screens/adminPanel/Designation/DesignationPanel")
);

const EditDesignation = React.lazy(() =>
  import("./Screens/adminPanel/Designation/EditDesignationPanel")
);
const SuperUserExtra = React.lazy(() =>
  import("./Screens/adminPanel/SuperUser/SuperUserExtra")
);
const SuperUserExtraHader = React.lazy(() =>
  import("./Screens/adminPanel/SuperUserExtra/SuperUserExtraHader")
);

const SuperUser = React.lazy(() =>
  import("./Screens/adminPanel/SuperUser/SuperUser")
);
// const useAutoLogout = React.lazy(() =>
//   import("./Screens/log-in/useAutoLogout")
// );

function App() {
  // useAutoLogout;
  const customToastStyle = {
    top: "10%",
    left: "85%",
    width: "330px",
    height: "10px",
    transform: "translate(-50%, -50%)",
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
  };
  const { isLoggedIn } = useContext(AuthContext);
  const { userPermissions, loading, getPermissions } = GetUserPermissions();
  const rolePermissions = userPermissions?.rolePermissions;

  useEffect(() => {
    getPermissions();
  }, [isLoggedIn]);

  // console.log(rolePermissions)

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <OfflineIndicator />
      <Suspense fallback={<div></div>}>
        <Router>
          {isLoggedIn && <Layout />}
          <Routes>
            <Route
              exact
              path="/"
              element={isLoggedIn ? <Navigate to="/home" /> : <LogIn />}
            />

            <Route
              exact
              path="/home"
              element={isLoggedIn ? <Home /> : <Navigate to="/" />}
            ></Route>
            <Route
              exact
              path="/punch-in-punch-out"
              element={isLoggedIn ? <PunchInPunchOut /> : <Navigate to="/" />}
            ></Route>
            <Route
              exact
              path="/leave-request"
              element={<LeaveRequest />}
            ></Route>
            <Route
              exact
              path="/shift-request"
              element={<ShiftRequest />}
            ></Route>
            <Route
              exact
              path="/over-time-request"
              element={isLoggedIn ? <OverTimeRequest /> : <Navigate to="/" />}
            ></Route>
            <Route
              exact
              path="/people-directory"
              element={isLoggedIn ? <PeopleDirectory /> : <Navigate to="/" />}
            ></Route>
            {rolePermissions &&
            rolePermissions.includes("Attendance Tracking - Admin") ? (
              <Route
                path="/attendance-tracking"
                element={
                  isLoggedIn ? <AttendanceTracking /> : <Navigate to="/" />
                }
              />
            ) : (
              <Route
                path="/attendance-tracking"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Leave Tracking - Admin") ? (
              <Route
                path="/leave-tracking"
                element={
                  isLoggedIn ? <LeaveReportTracking /> : <Navigate to="/" />
                }
              />
            ) : (
              <Route
                path="/leave-tracking"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Shift Tracking - Admin") ? (
              <Route
                path="/shift-tracking"
                element={
                  isLoggedIn ? <ShiftReportTracking /> : <Navigate to="/" />
                }
              />
            ) : (
              <Route
                path="/shift-tracking"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Overtime Tracking - Admin") ? (
              <Route
                path="/overtime-tracking"
                element={
                  isLoggedIn ? <OvertimeReportTracking /> : <Navigate to="/" />
                }
              />
            ) : (
              <Route
                path="/overtime-tracking"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            <Route
              exact
              path="/exit"
              element={isLoggedIn ? <ExitForm /> : <Navigate to="/" />}
            ></Route>
            <Route
              exact
              path="/emp-payslip"
              element={isLoggedIn ? <PayslipPanel /> : <Navigate to="/" />}
            ></Route>
            <Route
              exact
              path="/approval-report-history"
              element={
                isLoggedIn ? <ApprovalReportHistory /> : <Navigate to="/" />
              }
            ></Route>
            <Route
              exact
              path="/announcement"
              element={isLoggedIn ? <Announcement /> : <Navigate to="/" />}
            ></Route>
            <Route
              exact
              path="/daily-report"
              element={isLoggedIn ? <Dailyreport /> : <Navigate to="/" />}
            ></Route>
            <Route
              exact
              path="/forget-password"
              element={<ForgetPassword />}
            ></Route>
            <Route
              exact
              path="/ontap/privacy-policy"
              element={<PrivacyPolicy />}
            ></Route>

            <Route exact path="/enter-otp" element={<OtpEnter />}></Route>
            <Route
              exact
              path="/reset-password"
              element={<ResetPassword />}
            ></Route>

            {rolePermissions &&
            rolePermissions.includes("Permissions Panel - Admin") ? (
              <Route
                path="/permissions"
                element={isLoggedIn ? <PermissionPanel /> : <Navigate to="/" />}
              />
            ) : (
              <Route
                path="/permissions"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Roles Panel - Admin") ? (
              <Route
                exact
                path="/role"
                element={isLoggedIn ? <RolePanel /> : <Navigate to="/" />}
              />
            ) : (
              <Route
                path="/role"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}
            <Route
              exact
              path="/edit-role/:role_id"
              element={isLoggedIn ? <RoleEditPanel /> : <Navigate to="/" />}
            ></Route>

            {rolePermissions &&
            rolePermissions.includes("Employees Panel - Admin") ? (
              <Route
                exact
                path="/emp-onboarding"
                element={
                  isLoggedIn ? <EmpOnBoardingPanel /> : <Navigate to="/" />
                }
              ></Route>
            ) : (
              <Route
                path="/emp-onboarding"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}
            <Route
              exact
              path="/edit-userform/:employee_id"
              element={isLoggedIn ? <EmpEditPanel /> : <Navigate to="/" />}
            ></Route>

            {rolePermissions &&
            rolePermissions.includes("Leave Panel - Admin") ? (
              <Route
                exact
                path="/leave"
                element={isLoggedIn ? <LeaveListPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/leave"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Shift Panel - Admin") ? (
              <Route
                exact
                path="/shift"
                element={isLoggedIn ? <ShiftPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/shift"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Overtime Panel - Admin") ? (
              <Route
                exact
                path="/overtime"
                element={isLoggedIn ? <OverTimePanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/overtime"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Salary Management Panel- Admin") ? (
              <Route
                exact
                path="/salary-view"
                element={isLoggedIn ? <SalaryPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/salary-view"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Pay Slip panel - Admin") ? (
              <Route
                exact
                path="/salary-slip"
                element={isLoggedIn ? <SalarySlipPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/salary-slip"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Notifications Panel - Admin") ? (
              <Route
                exact
                path="/notification"
                element={
                  isLoggedIn ? <GetNotificationPanel /> : <Navigate to="/" />
                }
              ></Route>
            ) : (
              <Route
                path="/notification"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Policy Panel - Admin") ? (
              <Route
                exact
                path="/policy-view"
                element={isLoggedIn ? <PolicyPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/policy-view"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}
            <Route
              exact
              path="/edit-policy/:policy_id"
              element={isLoggedIn ? <EditPolicy /> : <Navigate to="/" />}
            ></Route>

            {rolePermissions &&
            rolePermissions.includes("Holiday Panel - Admin") ? (
              <Route
                exact
                path="/holiday-view"
                element={isLoggedIn ? <HolidayPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/holiday-view"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            <Route
              exact
              path="/edit-holidays/:holiday_id"
              element={isLoggedIn ? <EditHolidays /> : <Navigate to="/" />}
            ></Route>
            <Route
              exact
              path="/edit/salary/:employee_id"
              element={isLoggedIn ? <EditSalaryPanel /> : <Navigate to="/" />}
            ></Route>

            {rolePermissions &&
            rolePermissions.includes("Document Management - Admin") ? (
              <Route
                exact
                path="/employee/documentpanel"
                element={
                  isLoggedIn ? <EditDocumentPanel /> : <Navigate to="/" />
                }
              ></Route>
            ) : (
              <Route
                path="/employee/documentpanel"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            <Route
              exact
              path="/employee/document/edit/:employee_id"
              element={isLoggedIn ? <DocumentEditPanel /> : <Navigate to="/" />}
            ></Route>
            <Route
              exact
              path="/salary-slip-pdf"
              element={isLoggedIn ? <Salaryslippdf /> : <Navigate to="/" />}
            ></Route>

            {rolePermissions &&
            rolePermissions.includes("E-Exit Management - Admin") ? (
              <Route
                exact
                path="/exit-report"
                element={isLoggedIn ? <EExitReport /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/exit-report"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            <Route
              exact
              path="/profile"
              element={isLoggedIn ? <UserProfile /> : <Navigate to="/" />}
            ></Route>

            {rolePermissions &&
            rolePermissions.includes("Edit Attendance - Admin") ? (
              <Route
                exact
                path="/attendance-sheet"
                element={isLoggedIn ? <SheetPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/attendance-sheet"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Attendance Sheet - Admin") ? (
              <Route
                path="/edit-attendance"
                element={isLoggedIn ? <EditReportPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/edit-attendance"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            <Route
              path="/e-exit-hr"
              element={isLoggedIn ? <ExitFormHr /> : <Navigate to="/" />}
            ></Route>

            {rolePermissions &&
            rolePermissions.includes(
              "Add Organization Panel In Menu - Admin"
            ) ? (
              <Route
                path="/add-organization"
                element={isLoggedIn ? <AddOrgPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/add-organization"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes(
              "Add Organization Panel In Menu - Admin"
            ) ? (
              <Route
                path="/edit-organization"
                element={isLoggedIn ? <EditOrgPanel /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/edit-organization"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            <Route
              exact
              path="/edit-organization/:organization_id"
              element={isLoggedIn ? <EditOrgPanel /> : <Navigate to="/" />}
            ></Route>

            <Route
              path="/SuperUser"
              element={isLoggedIn ? <SuperUser /> : <Navigate to="/" />}
            ></Route>
            <Route
              path="/SuperUserExtra"
              element={isLoggedIn ? <SuperUserExtra /> : <Navigate to="/" />}
            ></Route>
            <Route
              path="/SuperUserExtraHader"
              element={
                isLoggedIn ? <SuperUserExtraHader /> : <Navigate to="/" />
              }
            ></Route>

            {rolePermissions &&
            rolePermissions.includes("Subscription Panel in Menu - Admin") ? (
              <Route
                path="/subscription"
                element={
                  isLoggedIn ? <SubscriptionPanel /> : <Navigate to="/" />
                }
              ></Route>
            ) : (
              <Route
                path="/subscription"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Subscription Panel in Menu - Admin") ? (
              <Route
                path="/edit-subscription"
                element={
                  isLoggedIn ? <EditSubscriptionPanel /> : <Navigate to="/" />
                }
              ></Route>
            ) : (
              <Route
                path="/edit-subscription"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            <Route
              exact
              path="/edit-subscription/:subscription_id"
              element={
                isLoggedIn ? <EditSubscriptionPanel /> : <Navigate to="/" />
              }
            ></Route>

            {rolePermissions &&
            rolePermissions.includes("Add Department In Menu - Admin") ? (
              <Route
                path="/add-department"
                element={isLoggedIn ? <Department /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/add-department"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Add Department In Menu - Admin") ? (
              <Route
                path="/edit-department"
                element={isLoggedIn ? <EditDepartment /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/edit-department"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            <Route
              path="/edit-department:department_Id"
              element={isLoggedIn ? <EditDepartment /> : <Navigate to="/" />}
            >
              {" "}
            </Route>

            {rolePermissions &&
            rolePermissions.includes("Add Designation In Menu - Admin") ? (
              <Route
                path="/add-designation"
                element={isLoggedIn ? <Designation /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/add-designation"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            {rolePermissions &&
            rolePermissions.includes("Add Designation In Menu - Admin") ? (
              <Route
                path="/edit-designation"
                element={isLoggedIn ? <EditDesignation /> : <Navigate to="/" />}
              ></Route>
            ) : (
              <Route
                path="/edit-designation"
                element={
                  <div>
                    <AccessDenied />
                  </div>
                }
              />
            )}

            <Route
              path="/edit-designation:designation_Id"
              element={isLoggedIn ? <EditDepartment /> : <Navigate to="/" />}
            >
              {" "}
            </Route>
          </Routes>
          {/* </Layout> */}
        </Router>
      </Suspense>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        closeButton={true}
        style={customToastStyle}
      />
    </>
  );
}

export default App;
