// src/Components/Layout.js
import React from "react";
import Header from "./header";
import "./header.css";
import "./responsive.css";

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main>{children}</main>
    </div>
  );
};

export default Layout;
